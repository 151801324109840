@use 'typography'
@use '../variables'

=input
    +typography.text

    width: 100%
    min-height: 2.625rem
    background-color: variables.$greyBackground
    border: 1px solid variables.$grey600
    border-radius: .3125rem
    padding: .3125rem .625rem
