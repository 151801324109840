@use 'common'

.wrapper
    //

.features
    //

.button
    // @TODO: generic spacing
    margin-top: 3.125rem
