@use 'common'

.wrapper
	display: flex
	flex-direction: column
	gap: .9375rem

.submit
	&.is_mobile
		@media (min-width: common.$break48)
			display: none

	&.is_desktop
		@media not all and (min-width: common.$break48)
			display: none

.terms
	font-size: .75rem
	color: common.$greyBasic
	line-height: 1.4
