@use 'common'

$productsDesktopBase: 28.125rem

.wrapper
	--OrderPage-gap: 2.5rem

.closeWrapper
	max-width: calc(73.75rem + (2 * var(--page-horizontal-spacing)))
	margin-inline: auto
	margin-top: 2.5rem
	padding-inline: var(--page-horizontal-spacing)

.link
	display: inline-block

	&:hover, &:focus-visible
		.close
			&:hover, &:focus-visible
				background: common.$grey800

.close
	position: relative
	width: 2rem
	height: 2rem
	z-index: 0
	background: linear-gradient(180deg, #181818 0%, #2B2B2B 100%)
	border-radius: 50%
	overflow: hidden
	transition: background .3s

	&::before
		transform: translate(-50%, -50%) rotate(45deg)

	&::after
		transform: translate(-50%, -50%) rotate(-45deg)

	&::before, &::after
		content: ''
		position: absolute
		top: 50%
		left: 50%
		display: inline-block
		width: 1.375rem
		height: .0625rem
		background-color: common.$greyBasic
		transform-origin: center center
		z-index: 0

.main
	display: flex
	flex-direction: column
	gap: var(--OrderPage-gap)
	position: relative
	max-width: calc(63.125rem + 2 * var(--page-horizontal-spacing))
	width: 100%
	margin-left: auto
	margin-right: auto
	padding-left: var(--page-horizontal-spacing)
	padding-right: var(--page-horizontal-spacing)
	padding-block: 1.25rem 5.3125rem
	z-index: 0

	@media not all and (min-width: common.$break48)
		padding-bottom: 21.875rem

	@media (min-width: common.$break48)
		flex-direction: row

	@media (min-width: common.$break62)
		--OrderPage-gap: 7.1875rem

.products
	width: 100%

	@media (min-width: common.$break48)
		flex-basis: calc(50% - (var(--OrderPage-gap) / 2))

.form
	z-index: -1

	@media (min-width: common.$break48)
		flex-basis: calc(50% - (var(--OrderPage-gap) / 2))
		margin-top: 6.25rem
