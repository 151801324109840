@use 'common'

.wrapper
    --CallToAction-element-verticalSpacing: 20px

    @media (min-width: common.$break35)
        --CallToAction-element-verticalSpacing: 2.5rem

.main
    $bottomSpacing: 2.5rem

    &.is_default
        height: calc(100vh - var(--header-estimated-height) - #{$bottomSpacing})
        height: calc(100svh - var(--header-estimated-height) - #{$bottomSpacing})
        display: flex
        flex-direction: column

        .background
            &Video
                position: relative
                flex-grow: 1

                &In
                    max-width: 100%
                    max-height: 100%
                    position: absolute
                    inset: 0
                    object-fit: contain
                    object-position: center
                    width: 100%
                    height: 100%

        .content
            margin-top: 1.5625rem

        .imageAboveContent
            margin-inline: auto
            margin-bottom: var(--CallToAction-element-verticalSpacing)

        .text
            margin-bottom: 1.875rem

        @media not all and (min-width: common.$break35)
            .content
                max-width: 14.5625rem
                margin-inline: auto

        @media (min-width: common.$break35)
            .imageAboveContent
                max-width: var(--CallToAction-imageAboveContent-width)

            .text
                margin-bottom: var(--CallToAction-element-verticalSpacing)

    &.is_option1
        position: relative
        display: flex
        flex-direction: column

        .content
            order: -1
            margin-bottom: 1.5625rem

        .actions
            position: absolute
            bottom: 1.25rem
            left: 0
            right: 0
            flex-direction: column
            gap: 1.875rem

        .button
            max-width: 13.125rem
            width: 100%

        @media (min-width: common.$break30)
            .button
                max-width: 18.125rem

        @media (min-width: common.$break48)
            margin-bottom: 3.125rem

            .actions
                bottom: 3.125rem
                flex-direction: initial

            .button
                max-width: initial
                width: auto

.imageAboveContent
    display: flex
    justify-content: center

    &In
        max-width: 100%
        height: auto

.background
    width: 100%
    height: 100%
    text-align: center

    &Video
        width: 100%
        height: 100%

        &In
            aspect-ratio: var(--CallToAction-video-aspectRatio)

    &Image
        max-width: 100%
        height: auto

    &.is_mobile
        @media (min-width: common.$break35)
            display: none

    &.is_desktop
        @media not all and (min-width: common.$break35)
            display: none

.title
    +common.heading1

    text-align: center
    color: common.$white
    margin-block: unset

.text
    +common.heading2

    margin-block: 1.125rem 0 0
    text-align: center

.actions
    display: flex
    justify-content: center
    align-items: center
    gap: 1.875rem
    padding-inline: 1.25rem

.button
    //
