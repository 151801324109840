@use 'common'

.wrapper

	> *:last-child
		&.section
			margin-bottom: var(--content-gap-default)

.section
	&.is_reference_button
		display: flex
		justify-content: center

	&:not(.is_wysiwyg) + &:not(.is_wysiwyg),
	&:not(.is_wysiwyg) + &.is_wysiwyg,
	&.is_wysiwyg p:empty
		margin-top: var(--content-gap-default)

	&.is_wysiwyg + &:not(.is_wysiwyg),
	&.is_wysiwyg + &.is_wysiwyg
		margin-top: var(--content-gap-small)

	$referencesBeforeButtonWithSmallGaps: ".is_reference_productTabsTiles, .is_reference_projection, .is_reference_featureSection"
	$wysiwygElementsBeforeHeadingsWithNoGaps: ".is_heading"
	$referencesAfterWysiwygWithMediumSpacingOnMobile: ".is_reference_projection"
	$referencesAfterImageWithSmallGap: ".is_wysiwyg"

	&.section

		&:is(#{$referencesBeforeButtonWithSmallGaps}) + &.is_reference_button
			margin-top: var(--content-gap-small)

		&:is(#{$wysiwygElementsBeforeHeadingsWithNoGaps}) + &.is_heading
			margin-top: unset

		&.is_wysiwyg + &:is(#{$referencesAfterWysiwygWithMediumSpacingOnMobile})
			@media not all and (min-width: common.$break48)
				margin-top: var(--content-gap-medium)

		&.is_reference_image + &:is(#{$referencesAfterImageWithSmallGap})
			margin-top: var(--content-gap-small)

.notImplemented
	border: 1px solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize
