@use '../variables'

=heading
	font-family: var(--font-heading)
	font-weight: 500
	margin-top: unset

	@media not all and (min-width: variables.$break48)
		br
			display: none

=heading1($isSpacing: true)
	+heading
	line-height: 1.1081
	font-size: 2.1875rem

	@if $isSpacing
		margin-bottom: 1rem

	@media (min-width: variables.$break22)
		font-size: 2.875rem

	@media (min-width: variables.$break48)
		font-size: 4.625rem

	@media (min-width: variables.$break62)
		font-size: 6.25rem

		@if $isSpacing
			margin-bottom: 2rem

=heading2($isSpacing: true)
	+heading
	font-size: 1.5rem
	line-height: 1.25

	@if $isSpacing
		margin-bottom: .5rem

	@media (min-width: variables.$break35)
		font-size: 1.875rem

	@media (min-width: variables.$break48)
		font-size: 2.5rem

		@if $isSpacing
			margin-bottom: 1rem

=perex
	font-family: var(--font-body)
	font-size: 1.125rem
	line-height: 1.25
	font-weight: 400
	margin-top: unset

	@media (min-width: variables.$break30)
		font-size: 1.625rem

=text
	font-size: .875rem
	line-height: 1.27
	font-weight: 400
	margin-top: unset

	@media (min-width: variables.$break30)
		font-size: 1.25rem

=highlight
	background: linear-gradient(278deg, #BF57FF 11.33%, #D1A3FF 49.5%, #9959FF 91.86%)
	-webkit-background-clip: text
	-webkit-text-fill-color: transparent
	background-clip: text
	text-fill-color: transparent
	font-style: inherit
