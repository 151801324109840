@use 'common'

$animationDuration: 2s

.wrapper
    --CaptionWithLine-animation-duration: 1.5s
    --CaptionWithLine-line-height: 3.4375rem
    --CaptionWithLine-line-thicknes: 1px

    &.is_inView

        .caption
            visibility: initial
            opacity: 1

            &Line
                &In
                    animation-name: progressLineMobile
                    animation-duration: var(--CaptionWithLine-animation-duration)
                    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1)

                    @keyframes progressLineMobile
                        0%
                            height: 0

                        100%
                            height: 100%

        .description
            visibility: initial
            opacity: 1

    &.is_reverse
        .caption
            &Line
                transform: rotate(180deg)

    &:not(&.is_vertical)
        .caption
            &Line
                margin-block: 1.875rem

    &.is_vertical
        .caption
            display: flex
            gap: 1.125rem
            height: fit-content

            &Line
                height: var(--CaptionWithLine-progressHeight)

    @media (min-width: common.$break64)
        &:not(&.is_vertical)
            .caption
                &Line
                    margin-block: 0 1rem
                    height: var(--CaptionWithLine-line-thicknes)

                    &In
                        position: absolute
                        left: 0
                        width: var(--CaptionWithLine-progressWidth, 100%)
                        height: var(--CaptionWithLine-line-thicknes)
                        margin: unset

                        @keyframes progressLine
                            0%
                                width: 0

                            100%
                                width: var(--CaptionWithLine-progressWidth, 100%)

                        &::after
                            right: 0
                            left: unset
                            top: -2px
        &:not(&.is_vertical)
            &.is_inView
                .caption
                    &Line
                        &In
                            animation-name: progressLine

.caption
    display: flex
    font-size: .875rem
    font-weight: 400
    color: common.$grey400
    visibility: hidden
    opacity: 0
    transition-property: visibility, opacity
    transition-duration: 0.4s

    &Line
        height: var(--CaptionWithLine-line-height)

        &In
            height: 100%
            width: var(--CaptionWithLine-line-thicknes)
            background-color: common.$grey400
            position: relative
            z-index: 0

            &::after
                content: ''
                position: absolute
                width: .3125rem
                height: .3125rem
                border-radius: 50%
                background-color: inherit
                bottom: 0
                left: -0.125rem
                z-index: 0

    @media not all and (min-width: common.$break64)
        flex-direction: column
        align-items: center
        justify-content: center
        text-align: center
        margin-inline: auto

    @media (min-width: common.$break48)
        font-size: 1.25rem

    @media (min-width: common.$break64)
        display: inline
        position: relative
        z-index: 0

    .description
        display: inline-block
        height: fit-content
        visibility: hidden
        opacity: 0
        transition-property: visibility, opacity
        transition-duration: 0.6s
        transition-delay: $animationDuration - 1s
