@use 'common'

=hideBreakElement
	br
		display: none

.wrapper

	&.is_breakableFrom_always
		//

	&.is_breakableFrom_768
		@media not all and (min-width: common.$break48)
			+hideBreakElement

	&.is_breakableFrom_992
		@media not all and (min-width: common.$break62)
			+hideBreakElement

.paragraph
	margin: inherit
