@use 'common'

.wrapper
	display: flex
	flex-direction: column
	gap: 2.1875rem

	.strikethrough
		color: common.$greyBasic
		text-decoration: line-through

		&.rightGap
			margin-right: .125rem

		&.is_small
			font-size: 0.65em

.description
	color: common.$grey400

.features
	color: common.$grey400

.summary
	font-size: .875rem

	@media not all and (min-width: common.$break48)
		position: fixed
		inset: auto 0 0
		padding: 1.5rem .9375rem
		background-color: #000000
		z-index: 1

	&DiscountLabel
		width: fit-content
		background-color: common.$grey800
		color: common.$greyBasic
		padding: .25rem .625rem
		border-radius: 1.5rem
		font-size: .75rem
		margin-block: .3125rem

	&Total
		margin-block: .3125rem
		display: inline-flex
		gap: 1.25rem
		align-items: flex-end

	&Table
		//

	&Row
		//

	&Cell
		padding: unset
		vertical-align: initial
		padding-top: .3125rem

		&.is_price
			white-space: pre
			padding-right: .3125rem

		&:not(.is_price)
			color: common.$greyBasic

	&Title, &List
		color: common.$greyBasic

.actions
	margin-top: 1.5rem

	@media (min-width: common.$break48)
		display: none
