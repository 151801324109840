@use 'common'

.wrapper
	text-align: center
	padding-inline: var(--page-horizontal-spacing)
	color: common.$greyBasic
	visibility: hidden
	opacity: 0
	position: fixed
	top: 50%
	left: 0
	right: 0
	transform: translateY(-50%)
	max-width: calc(33.125rem + (2 * var(--page-horizontal-spacing)))
	width: 100%
	margin-inline: auto
	transition-property: visibility, opacity
	transition-duration: 0.3s
	z-index: 1

	&.is_visible
		visibility: initial
		opacity: 1

.main
	padding: 1.875rem 2.5rem
	background-color: common.$grey900
	border-radius: 1.25rem
	overflow: hidden

	@media (min-width: common.$break48)
		padding: 6rem 5.625rem 2.375rem

.state
	width: fit-content
	display: flex
	align-items: center
	gap: .3125rem
	margin-inline: auto
	padding: .375rem .625rem
	background-color: common.$grey800
	border-radius: 1.5rem

.icon
	display: flex

.message
	margin-block: .75rem 0
	text-align: center

.button
	margin-top: 2.5rem

	@media (min-width: common.$break48)
		margin-top: 5.3125rem
