@use 'common'
@use 'sass:color'

.wrapper
    position: relative
    line-height: 1.285
    border-radius: 0.625em
    min-height: var(--Button-height, 1.875rem)
    min-width: var(--Button-width, 3rem)
    padding: .375rem .9375rem
    gap: 1em
    display: inline-flex
    justify-content: center
    overflow: hidden
    transition-property: background-color border-color
    transition-duration: .6s
    cursor: pointer
    text-decoration: unset
    border-width: 1px
    border-style: solid

    &.is_size_medium
        --Button-height: 2.75rem
        --Button-width: 11.8125rem

    &.is_visualType_default
        align-items: center

        &.is_fill_transparent
            color: common.$greyBasic

            &:hover
                background-color: color.adjust(common.$greyBasic, $lightness: -50%)

        &.is_fill_light
            background-color: common.$white
            color: #000000
            border-color: common.$white

            &:hover
                background-color: transparent
                border-color: common.$white
                color: common.$white

    &.is_visualType_outline
        border-color: common.$grey600
        color: common.$greyBasic

        &:hover, &:focus-visible
            background-color: common.$white
            border-color: common.$white
            color: common.$greyBlack

        &.is_width_wide
            width: 100%

    &.is_fullSize
        width: 100%

    &.is_arrow
        padding: 1.5rem

    &:not(.is_arrow)
        font-size: .875rem
        font-weight: 500

    @media (min-width: common.$break48)
        &.is_arrow
            min-height: var(--Button-height, 8.25rem)

            .text
                align-self: flex-start

.content
    display: flex
    justify-content: space-between
    align-items: flex-end
    gap: 1.25rem
    width: 100%

.text
    +common.text

    max-width: 15.3125rem

.arrow
    display: flex
    width: 1.375rem
    color: inherit
