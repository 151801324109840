@use 'common'

.wrapper
    //

.tiles
    display: grid
    gap: 1.5625rem

    @media (min-width: 540px)
        grid-template-columns: 1fr 1fr

.tile

    &In
        padding: 1.875rem
        display: flex
        flex-direction: column
        border-radius: 1.25rem
        overflow: hidden
        background: linear-gradient(180deg, #000000 0%, #1C1C1C 122.49%)

.products
    position: relative
    z-index: 0
    margin-top: .9375rem

.product
    position: absolute
    z-index: 0
    top: 0
    right: 0
    left: 0
    visibility: hidden
    opacity: 0

    &Content
        //

    &Image
        display: flex
        justify-content: center

        &In
            max-width: 100%
            height: auto

    &Name
        +common.heading2

        display: inline
        margin-top: 1.25rem
        color: common.$greyBasic
        margin-right: .8125rem

    &ShortInfo
        +common.highlight

        display: inline-block

    &Description
        color: common.$grey500
        margin-block: 1.25rem 0

    &SaleLabel
        background-color: common.$grey800
        color: common.$greyBasic
        padding: .25rem .625rem
        border-radius: 1.5rem
        font-size: .75rem
        margin-bottom: 1.0625rem

    &Action
        margin-top: 2rem
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

        .strikethrough
            color: common.$grey400
            text-decoration: line-through

    &.is_active
        position: relative
        visibility: initial
        opacity: 1
        z-index: 0

.tabs
    display: flex
    justify-content: center
    gap: .3125rem

    &Label
        +common.text

        text-align: center
        color: common.$grey500
        font-weight: 400

.tab
    padding: .5rem .625rem
    border-radius: .3125rem
    font-size: 1.125rem
    color: common.$grey400
    transition-property: color, background-color
    transition-duration: .2s

    &:hover
        background-color: rgba(common.$grey700, .5)

    &.is_active
        color: common.$white
        background-color: common.$grey700

.waitlist
    width: 100%
    &Info
        font-size: .75rem
        font-weight: 400
        color: common.$greyBasic
        margin-top: .625rem

    &Submit
        text-align: center
        margin-top: 1.25rem
