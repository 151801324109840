@use 'common'
@use 'sass:color'

.wrapper
	a
		&:hover, &:focus-visible
			// color: color.adjust(common.$blue, $lightness: 15%)

	h1, h2
		//

	p
		margin-top: 0

	em
		+common.highlight

	[data-contember-align="center"]
		text-align: center

	[data-contember-align="end"]
		text-align: end

	[data-contember-type="heading"]
		width: fit-content
		margin-inline: auto
