@use 'common'

.wrapper
    padding: 1.875rem 0 1.25rem
    border-bottom: 1px solid common.$grey600
    position: relative
    z-index: 0

    &::after
        content: ''
        position: absolute
        inset: 0
        background: rgba(0, 0, 0, 0.5)
        backdrop-filter: blur(.625rem)
        box-shadow: inset 0 0 3.125rem #000000
        z-index: -1

.main
    display: flex
    justify-content: space-between
    align-items: center
    font-size: .875rem

.logo
    $logoPadding: 0.5rem
    display: flex
    margin: -$logoPadding

    &Link
        padding: $logoPadding

.column
    //
