@use 'common'

$gap: 3.125rem
$tileVerticalSpacing: 1.5625rem

// @TODO: animations
.wrapper
    //

.tiles
    display: grid
    grid-template-columns: 1fr
    gap: 1.25rem

    @media (min-width: common.$break40)
        gap: 3.125rem
        grid-template-columns: 1fr 1fr

    @media (min-width: common.$break62)
        grid-template-columns: repeat(5, 1fr)

.tile
    --FeatureSection-spacing-inline: 1.25rem
    --FeatureSection-spacing-block: 1.25rem

    background: linear-gradient(180deg, #000000 0%, #1C1C1C 122.49%)
    border-radius: 1.875rem
    overflow: hidden
    display: flex
    flex-direction: column
    padding: var(--FeatureSection-spacing-block) var(--FeatureSection-spacing-inline)

    @media (min-width: common.$break40)
        --FeatureSection-spacing-block: 1.5625rem
        --FeatureSection-spacing-inline: 2.1875rem

    &.is_antimer
        align-items: center

        .header
            display: flex
            flex-direction: column
            align-items: center

            .label1, .label2
                +common.perex

                color: common.$greyBasic

            .number
                +common.heading1(false)
                +common.highlight

        .description
            margin-top: $tileVerticalSpacing

        .image
            margin-top: $tileVerticalSpacing

        @media (min-width: common.$break62)
            grid-row: span 3
            grid-column: span 2

    &.is_heat
        color: common.$greyBasic

        .title
            margin-bottom: 2.625rem
            line-height: 1

            @media (min-width: common.$break40)
                margin-bottom: 3.75rem

        .highlighted
            +common.highlight

        .shifted
            position: relative
            top: 55%
            z-index: 0

        .image
            margin-top: $tileVerticalSpacing

        @media (min-width: common.$break62)
            grid-row: span 2
            grid-column: span 3

    &.is_pump
        justify-content: space-between
        .header
            display: flex
            flex-direction: row
            align-items: center
            justify-content: center
            gap: 1.5rem

        .title
            margin: unset

        .column
            //

        .sideTitles
            //

        .sideTitle
            +common.perex

            &:first-child
                color: common.$greyBasic

            &:nth-child(2)
                color: common.$grey400

            &:nth-child(3)
                color: common.$grey600

            &:nth-child(4)
                color: common.$grey700

            &:nth-child(5)
                color: common.$grey800

        .description
            text-align: center
            margin-top: $tileVerticalSpacing
            margin-inline: auto

        @media (min-width: common.$break62)
            grid-row: 4 / 5
            grid-column: 1 / 3
            padding: 5rem 5rem var(--FeatureSection-spacing-block)

    &.is_temperature
        justify-content: flex-end

        .header
            position: relative
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            padding-block: 3.75rem
            z-index: 0

        .label
            +common.perex

            max-width: 14.375rem
            margin-bottom: .625rem
            text-align: center
            color: common.$greyBasic

        .unit
            +common.heading2(false)
            +common.highlight

            vertical-align: top

        .number
            +common.heading1(false)
            +common.highlight

            line-height: 1
            padding-left: 1.875rem

        .background
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            z-index: -1

            &::before
                content: ''
                position: absolute
                width: 5.625rem
                height: 5.625rem
                background: linear-gradient(180deg, #000000 0%, #1C1C1C 120%)
                border-radius: 50%
                border: 1px solid common.$grey600
                z-index: 1
                opacity: 0.4

            &::after
                content: ''
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                width: 12.5rem
                height: 12.5rem
                background: rgba(191, 87, 255, 0.15)
                filter: blur(3.125rem)

        .description
            align-self: center
            text-align: center

        @media (min-width: common.$break48)
            .background
                &::before
                    width: 8.125rem
                    height: 8.125rem

                &::after
                    width: 16.25rem
                    height: 16.25rem

        @media (min-width: common.$break62)
            grid-row: 3 / 5
            grid-column: span 3

            .header
                padding-top: unset
                padding-bottom: 4.375rem

    &.is_tube
        gap: 2.5rem

        padding-right: unset

        @media not all and (min-width: common.$break40)
            .image
                &.is_mobile
                    margin-left: auto

                &.is_desktop
                    display: none

            .content
                padding-right: var(--FeatureSection-spacing-inline)

        @media (min-width: common.$break40)
            flex-direction: row
            justify-content: space-between
            align-items: center
            grid-column: span 2

            .image
                &.is_mobile
                    display: none

        @media (min-width: common.$break62)
            grid-column: span 5

    .title
        +common.heading2(false)

        color: common.$greyBasic

    .description
        +common.text

        max-width: 15.625rem
        color: common.$grey400
        font-weight: 400
        align-self: flex-start

        @media (min-width: common.$break40)
            max-width: 25.9375rem

    .image

        &In
            max-width: 100%
            height: auto
