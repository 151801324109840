@use 'common'
@use 'sass:color'

.wrapper

    &.is_default
        //

    &.is_textarea
        //

    &.is_focused
        //

.main
    //

.label
    font-size: .875rem
    color: common.$greyBasic
    margin-bottom: .625rem
    cursor: pointer

.select
    width: 100%
    color: common.$greyBasic

    @media (min-width: common.$break62)
        margin: auto

    :global
        .select__control
            display: flex
            overflow: hidden
            border: unset
            cursor: pointer
            caret-color: inherit

            +common.input

            &:hover, &--is-focused
                cursor: pointer
                box-shadow: unset
                border: 1px solid common.$grey600
                outline: initial

        .select__value
            &-container
                font-size: .875rem
                padding: unset

        .select__placeholder
            font-size: .875rem
            color: common.$greyBasic
            opacity: .5

        .select__menu
            background-color: transparent

            &-list
                color: common.$greyBasic
                background-color: common.$greyBackground
                font-size: .875rem

                padding: unset
                border: 1px solid common.$grey600
                border-radius: .3125rem

        .select__input
            &-container
                color: inherit
                margin: unset
                padding: unset

        .select__single
            &-value
                color: inherit

        .select__option

            &--is-selected
                background-color: common.$greyBasic

            &:hover, &--is-focused
                background-color: common.$grey500
                color: common.$white

        .select__indicator
            padding-block: .125rem
