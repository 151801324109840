@use 'common'

.wrapper
    //

.list
    display: grid
    justify-content: center
    gap: 1.25rem
    row-gap: 2.5rem
    list-style-type: unset
    padding: unset
    margin: unset

    @media (min-width: common.$break30)
        grid-template-columns: 1fr 1fr
        justify-content: space-between

    @media (min-width: common.$break48)
        grid-template-columns: 1fr 1fr 1fr

.item
    display: grid
    grid-template-columns: auto 1fr
    align-items: flex-start
    gap: .9375rem
    color: common.$greyBasic

.icon
    display: flex
    min-width: 1.5625rem

.title
    +common.perex
    margin-block: unset

    @media (min-width: common.$break30)
        max-width: 10.625rem
