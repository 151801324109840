@use 'common'

.wrapper
    display: flex
    gap: 1.25rem
    padding: 1.125rem 1.4375rem
    border: 1px solid common.$grey600
    border-radius: .625rem
    overflow: hidden
    min-height: 5.125rem
    cursor: pointer
    transition-property: border-color, background-color
    transition-duration: .2s

    &.is_checked
        border-color: common.$white
        color: common.$white

        .price
            color: common.$white

    &:hover, &.is_focused
        background-color: #181818

.checkbox
    margin: unset
    position: absolute
    height: 0
    width: 0

    &:checked ~ &Custom
        background-color: common.$grey600
        border-color: common.$white

        &::before
            content: ''
            visibility: initial
            opacity: 1

    &Custom
        position: relative
        align-self: center
        flex-shrink: 0
        width: 1.375rem
        height: 1.375rem
        border: 1px solid common.$grey600
        border-radius: .25rem
        z-index: 0
        transition-property: background-color, border-color
        transition-duration: .2s

        &::before
            content: ''
            position: absolute
            top: 50%
            left: 50%
            width: .5em
            height: .3125em
            transform: translate(-50%, -70%) rotate(-45deg)
            visibility: hidden
            opacity: 0
            border-left: .125rem solid common.$white
            border-bottom: .125rem solid common.$white
            transition-property: visibility, opacity
            transition-duration: .2s

.content
    display: flex
    justify-content: space-between
    gap: 1.25rem
    flex-grow: 1
    font-size: .875rem

.title
    max-width: 16.25rem

.price
    color: inherit
    white-space: nowrap
