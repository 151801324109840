@use 'common'

$firstColumnWidth: 50%

.wrapper
    @media (min-width: common.$break30)
        --ContentImageWithFeatures-column-gap: 1.25rem

    @media (min-width: common.$break48)
        --ContentImageWithFeatures-column-gap: 3.75rem

    @media (min-width: common.$break62)
        --ContentImageWithFeatures-column-gap: 5.625rem

    @media (min-width: common.$contentWidth-medium)
        --ContentImageWithFeatures-column-gap: 8.125rem

.main
    display: flex
    flex-direction: column
    gap: var(--ContentImageWithFeatures-column-gap)

    @media (min-width: common.$break30)
        flex-direction: row

.column
    @media not all and (min-width: common.$break30)
        &:first-child
            text-align: center

    @media (min-width: common.$break30)
        &:first-child
            flex-basis: calc(50% - (var(--ContentImageWithFeatures-column-gap) / 2))
            display: flex
            flex-direction: column

        &:last-child
            order: -1
            flex-basis: calc(50% - (var(--ContentImageWithFeatures-column-gap) / 2))
            display: flex
            flex-direction: column
            margin-top: 3.75rem

            @media (min-width: common.$break48)
                margin-top: 5rem

            @media (min-width: common.$break62)
                margin-top: 8.75rem

.image
    &In
        max-width: 100%
        height: auto
    @media not all and (min-width: common.$break30)
        padding-inline: 2.5rem

    @media (min-width: common.$break30)
        max-width: 80%
        display: flex
        align-self: flex-end

.text
    +common.heading2

    margin-block: unset
    max-width: 53.75rem

.subtitle
    +common.heading2
    +common.highlight

    @media not all and (min-width: common.$break30)
        font-size: 24px
.title
    +common.heading1

.features
    display: grid
    column-gap: var(--ContentImageWithFeatures-column-gap)
    row-gap: 3.125rem
    margin-top: 3.125rem

    @media (min-width: common.$break35)
        grid-template-columns: 1fr 1fr

    @media (min-width: common.$break48)
        margin-top: 4.375rem
        grid-template-columns: repeat(6, 1fr)
        justify-content: space-between

.feature

    &Icon
        margin-bottom: 1rem

    &Title
        +common.perex
        color: common.$white
        margin-block: 0 0.5rem

    &Text
        +common.text
        color: common.$grey400
        margin-block: unset
        font-weight: 400

    @media (min-width: common.$break48)

        &:first-child,
        &:nth-child(2n),
        &:nth-child(3n)
            grid-column: span 2

        &:nth-child(4n),
        &:nth-child(5n)
            grid-column: span 3

    @media (min-width: common.$contentWidth-medium)
        $columnTotalGap: 14.0625rem

        &:nth-child(4n)
            padding-right: calc((#{$columnTotalGap} - var(--ContentImageWithFeatures-column-gap)) / 2)

        &:nth-child(5n)
            padding-left: calc((#{$columnTotalGap} - var(--ContentImageWithFeatures-column-gap)) / 2)
