@use 'common'

.wrapper
    display: flex
    flex-direction: column
    gap: 1.625rem

.title
    +common.perex

    margin: unset

.actions
    display: flex
    flex-direction: column
    gap: .9375rem

    @media not all and (min-width: common.$break48)
        display: none

.submit
    //

.terms
    font-size: .75rem
    color: common.$greyBasic
    line-height: 1.4
