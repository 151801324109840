@use 'common'

$buttonHalfHeight: .625rem

.wrapper
    //

.images
    display: flex
    position: relative
    z-index: 0

.image
    position: absolute
    inset: 0
    flex-basis: 100%
    visibility: hidden
    opacity: 0
    transition-property: visibility, opacity
    transition-duration: .2s
    z-index: 0

    &.is_active
        position: relative
        visibility: initial
        opacity: 1

    &In
        width: 100%
        height: auto

.buttons
    display: grid
    grid-template-columns: repeat(var(--ProjectionMobile-buttons-count, 3), 1fr)
    gap: .9375rem
    margin-top: calc(3.125rem - $buttonHalfHeight)
    padding-bottom: $buttonHalfHeight

.button
    height: 1px
    background-color: common.$grey600
    position: relative
    z-index: 0
    transition: background-color .2s

    &.is_active
        background-color: common.$white

    &::before, &::after
        content: ''
        position: absolute
        left: 0
        width: 100%
        height: $buttonHalfHeight
        z-index: 0
        cursor: pointer

    &::before
        bottom: 100%

    &::after
        top: 100%

.content
    position: relative
    z-index: 0

    &In
        position: absolute
        inset: 0
        visibility: hidden
        opacity: 0

        &.is_active
            position: relative
            visibility: initial
            opacity: 1

.title
    margin-block: calc(1rem - $buttonHalfHeight) 0

.text
    color: common.$greyBasic
