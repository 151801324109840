@use 'common'

$rowGap: 2.5rem
$fontSize: .875rem

.wrapper
	font-size: .875rem
	line-height: 1.285

.main
	padding-bottom: 2.5rem

.content
	display: flex
	justify-content: space-between
	gap: 12.5rem
	row-gap: $rowGap
	flex-wrap: wrap

.logos
	display: flex
	gap: 2.5rem

.divider
	width: 100%
	height: 1px
	background: common.$grey600
	margin-block: 1.25rem 1rem

	@media (min-width: common.$break48)
		margin-top: 8.75rem

.bar
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	gap: 1.875rem

.contact
	margin-top: 1.25rem

.columns
	display: flex
	gap: 5rem
	row-gap: $rowGap
	flex-wrap: wrap

	@media (min-width: common.$break48)
		padding-right: 12.5rem

.column
	&Title
		margin: unset
		font-weight: 400
		font-size: inherit

.link
	color: common.$greyBasic
	font-size: inherit
	line-height: 1.285
	padding-block: .3125rem
	text-decoration: none
	font-weight: 400

	&:hover, &:focus-visible
		text-decoration: underline

	&List
		list-style-type: none
		padding: unset
		margin: unset

		display: flex
		flex-direction: column
		gap: .9375rem
		margin-top: .9375rem

	&Item
		color: common.$greyBasic

.address
	font-style: inherit

.copyright,
.contact
	color: common.$greyBasic
	font-weight: 400

.bitcoinPrice
	&Main
		display: flex
		gap: .625rem

	&In
		color: common.$greyBasic
		display: flex
		gap: 1.25rem

	&Value
		color: common.$white

	&Source
		color: common.$greyBasic
