@use 'common'

$rowGap: 2.5rem
$mainColumnWidth: 56%
$rightColumnWidth: 20%
$lineThickness: 1px

.wrapper
    //

.items
    display: grid
    gap: 3.125rem

    @media (min-width: common.$break25)
        grid-template-columns: 1fr 1fr
        column-gap: 1.5625rem

    @media (min-width: common.$break64)
        grid-template-columns: 18% $mainColumnWidth $rightColumnWidth
        gap: 3%
        row-gap: $rowGap
        align-items: flex-start

.item
    position: relative
    display: grid

    @media (min-width: common.$break64)
        &:first-child

            .caption
                margin-top: 3.125rem

        &:nth-child(2)
            .caption
                position: absolute
                left: calc(100% + 1.5625rem)
                width: calc((100% / $mainColumnWidth) * $rightColumnWidth)

        &:nth-child(4)
            $imageWidth: 140%

            .image
                max-width: $imageWidth

            .caption
                position: absolute
                left: calc($imageWidth + 1.5625rem)
                bottom: 2.5rem
                width: 100%

        &:nth-child(6)
            margin-top: calc(-1 * (100% + #{$rowGap}))

            .caption
                position: absolute
                right: calc(100% + 1.5625rem)
                top: calc(100% - 3.125rem)
                width: 100%

    @media not all and (min-width: common.$break64)
        &.is_empty
            display: none

.image
    max-width: 100%
    height: auto
    overflow: hidden

    &.is_nonTile
        margin-bottom: 2.5rem

    &.is_desktop
        border-radius: 2.5rem

        @media not all and (min-width: common.$break64)
            display: none

    &.is_mobile
        border-radius: 1.875rem

        @media (min-width: common.$break64)
            display: none

.caption
    position: relative
    max-width: 15.625rem
    color: common.$grey400
    margin-top: .9375rem

    &.is_desktop
        @media not all and (min-width: common.$break64)
            display: none

    &.is_mobile
        @media (min-width: common.$break64)
            display: none
