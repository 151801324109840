$white: #FFFFFF
$greyBasic: #ADADAD
$grey400: #8C8C8C
$grey500: #737373
$grey600: #545454
$grey700: #413F40
$grey800: #303030
$grey900: #181818
$greyBlack: #060606
$greyBackground: #2E2E2E
$colorSuccess: #155724
$colorSuccessBackground: #D4EDDA
$colorSuccessBorder: #C3E6CB
$colorError: #721c24
$colorErrorBackground: #F8D7DA
$colorErrorBorder: #F5C6CB

$break22: 22rem
$break25: 25rem
$break30: 30rem
$break35: 35rem
$break40: 40rem
$break48: 48rem
$break62: 62rem
$break64: 64rem
$break75: 75rem

$contentWidth-normal: 57.5rem
$contentWidth-medium: 78.125rem
$contentWidth-wide: 90rem
$header-estimatedHeight: 5.5rem
