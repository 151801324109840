@use 'common'

.wrapper
    //

.main
    display: flex
    flex-direction: column
    gap: 2.1875rem

.image
    max-width: 100%
    height: auto
    display: flex
    margin-inline: auto
    position: absolute
    opacity: 0
    visibility: hidden
    z-index: -1
    inset: 0

    &.is_active
        position: relative
        visibility: initial
        opacity: 1
        z-index: 0

.header
    display: flex
    flex-direction: column
    gap: 1.25rem

    &Logo
        max-width: 9.375rem
        width: 100%

        &In
            width: 100%

    @media (min-width: common.$break48)
        &Logo
            max-width: 10.625rem

    @media (min-width: common.$break64)
        &Logo
            max-width: 11.875rem

    @media (min-width: common.$break30)
        flex-direction: row
        justify-content: space-between
        align-items: center

.title
    margin: unset

    @media not all and (min-width: common.$break30)
        text-align: center

.select
    @media (min-width: common.$break30)
        flex-basis: 15rem
        width: 15rem

    :global
        .select__control
            border: unset
            min-height: 1.75rem
            background: linear-gradient(180deg, #000 0%, #1C1C1C 100%), #060606
            min-height: 2.375rem

            &:hover, &--is-focused
                border: unset

            @media (min-width: common.$break48)
                min-height: 2.875rem

        .select__value
            &-container
                font-size: 1.125rem

            @media (min-width: common.$break48)
                &-container
                    font-size: 1.25rem

            @media (min-width: common.$break62)
                &-container
                    font-size: 1.625rem

        .select__single-value
            margin-inline: unset

        .select__menu

            &-list
                background: linear-gradient(180deg, #000 0%, #1C1C1C 100%), #060606

.item
    position: absolute
    visibility: hidden
    opacity: 0
    z-index: -1

    &.is_active
        position: relative
        visibility: initial
        opacity: 1
        z-index: 0
