@use 'common'

.wrapper

    &:not(.is_descriptionLayout)
        text-align: center

    &.is_descriptionLayout
        display: grid
        justify-content: center
        justify-items: center

        @media (min-width: common.$break64)
            grid-template-columns: minmax(9.375rem, 16.5625rem) minmax(37.5rem, 40.625rem) minmax(9.375rem, 16.5625rem)
            gap: 1.25rem
            align-items: flex-end

            .side
                &:last-child
                    margin-bottom: 3vw

    &.is_imageWithWhiteShadow

        .image
            position: relative
            z-index: 0

            &::after
                content: ''
                aspect-ratio: 1
                position: absolute
                inset: calc(-1 * var(--content-gap-small) - 3.125rem) 0
                background-image: radial-gradient(rgba(251, 240, 240, 0.4) -30%, transparent 70%)
                z-index: -1
                background-size: 100%
                background-repeat: no-repeat
                background-position: center

.image
    display: flex
    width: 100%
    max-width: var(--ImageBlock-image-width)

    &In
        max-width: 100%
        height: auto
