@use 'common'

.wrapper
    --AsideContentMedia-content-flexBasis: 50%

    @media (min-width: common.$break62)
        --AsideContentMedia-content-flexBasis: 45%

.main
    display: flex
    justify-content: center
    flex-direction: column
    gap: 1.25rem

    @media not all and (min-width: common.$break35)
        text-align: center

    @media (min-width: common.$break35) and (max-width: calc(common.$break48 - 1px))
        align-items: center

    @media (min-width: common.$break35)
        flex-direction: row

.content
    @media (min-width: common.$break35)
        flex-basis: var(--AsideContentMedia-content-flexBasis)

.subtitle
    +common.heading2
    +common.highlight

    @media (min-width: common.$break48)
        margin-bottom: .625rem

.title
    +common.heading1

    @media (min-width: common.$break48)
        width: 150%

.tableWrapper
    font-size: .875rem

    @media (min-width: common.$break35)
        font-size: 1.25rem

    &.is_mobile

        @media (min-width: common.$break35)
            display: none

    &.is_desktop
        @media not all and (min-width: common.$break35)
            display: none

.tableSpecifiation
    width: 100%
    margin-top: .9375rem

    &Row
        display: flex
        justify-content: space-between
        border-bottom: 1px solid common.$grey600
        padding-bottom: 1.125rem

        &:not(:first-child)
            padding-top: 1.125rem

    &Cell
        font-weight: 400
        &.is_label
            //

        &.is_value
            color: common.$greyBasic

    @media (min-width: common.$break35)
        max-width: 23.125rem
        margin-top: 3.125rem

    @media (min-width: common.$break48)
        margin-top: 5rem

.link
    display: inline-block
    margin-top: 1.875rem
    font-size: 1.125rem
    color: common.$greyBasic

    @media not all and (min-width: common.$break35)
        text-align: center

    @media (min-width: common.$break35)
        font-size: inherit

.media

    @media (min-width: common.$break35)
        flex-basis: calc(100% - var(--AsideContentMedia-content-flexBasis))

    @media (min-width: common.$break48)
        align-self: flex-end

    @media (min-width: common.$break62)
        margin-top: 7.5rem

    &In
        max-width: 100%
        height: auto
