@use 'common'

.wrapper
    .is_mobile
        @media (min-width: common.$break62)
            display: none

    .is_desktop

        @media not all and (min-width: common.$break62)
            display: none

.columns
    display: grid
    grid-template-columns: 13.125rem auto

$slide-height: 75vh
.column
    position: relative
    z-index: 0

    &.is_items
        position: relative
        z-index: 0
        align-items: center
        height: calc((var(--Projection-items-count) + 0.5) * #{$slide-height})

    .items
        height: $slide-height
        position: sticky
        top: calc((100vh - #{$slide-height}) / 2)
        z-index: 0

.anchor
    position: absolute
    top: calc(((var(--Projection-anchor-index) + 1) * #{$slide-height}))
    left: 0
    width: 100%
    height: 100vh
    scroll-margin-top: -1px

    // For debugging purposes
    // background: rgba(red, 0.2)

.item
    gap: .625rem
    position: absolute
    inset: 0
    z-index: 0
    display: flex
    align-items: center
    justify-content: center

    &.is_active

        .image
            visibility: initial
            opacity: 1

.title
    font-size: 1.25rem
    line-height: 1.25
    margin-block: 0 1rem
    font-weight: 400

.content
    position: absolute
    top: calc(var(--Projection-content-index) * #{$slide-height} + #{$slide-height} / 2)
    opacity: var(--Projection-content-progress)
    transition-property: transform, visibility, opacity
    transition-duration: .2s
    transform: translateY(-50%)

    &.is_active
        visibility: initial
        opacity: 1

.text
    margin: unset
    font-weight: 400
    color: common.$greyBasic

.image
    height: 100%
    display: flex
    justify-content: center
    visibility: hidden
    opacity: 0
    transition-property: visibility, opacity
    transition-duration: .2s

    &In
        max-width: 100%
        height: auto
